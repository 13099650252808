.navBar {
    height: 10vh;
    width: 100vw;
    box-shadow: 0 2px 4px rgb(0 0 0 / 24%);
    display: flex;
    align-items: center;
    position: fixed;
    background-color: #ffffff;
    z-index: 1;
    margin: 0;
}

.narBar > div {
    display: flex;
}

.navBarContainer {
    flex: 1;
}

.navBarContainer > a > img {
    height: 24px;
    padding: 0 32px;
}

.userProfile {
    padding: 0 32px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 150px;
    justify-content: flex-end;
}

.userProfile:hover,
.closeIcon:hover {
    cursor: pointer;
    opacity: 0.6;
}

.userProfile > div {
    justify-content: center;
    align-items: center;
}

.userName {
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
}

.sideBar {
    height: 100vh;
    width: 400px;
    position: absolute;
    top: 0;
    background-color: #ffffff;
    transition: 0.15s;
}

.closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
}

.profileSection {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 320px;
    background: #e87722;
}

.profileSection > div {
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .sideBar {
        width: 100vw;
    }
    .userName {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
