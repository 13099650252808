@font-face {
    font-family: "FWDCircularTT-Black";
    src: local("FWDCircularTT-Black"),
        url("./assets/fonts/FWDCircularTT-Black.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FWDCircularTT-BlackItalic";
    src: local("FWDCircularTT-BlackItalic"),
        url("./assets/fonts/FWDCircularTT-BlackItalic.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FWDCircularTT-Bold";
    src: local("FWDCircularTT-Bold"),
        url("./assets/fonts/FWDCircularTT-Bold.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FWDCircularTT-BoldItalic";
    src: local("FWDCircularTT-BoldItalic"),
        url("./assets/fonts/FWDCircularTT-BoldItalic.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FWDCircularTT-Book";
    src: local("FWDCircularTT-Book"),
        url("./assets/fonts/FWDCircularTT-Book.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FWDCircularTT-BookItalic";
    src: local("FWDCircularTT-BookItalic"),
        url("./assets/fonts/FWDCircularTT-BookItalic.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FWDCircularTT-Medium";
    src: local("FWDCircularTT-Medium"),
        url("./assets/fonts/FWDCircularTT-Medium.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FWDCircularTT-MediumItalic";
    src: local("FWDCircularTT-MediumItalic"),
        url("./assets/fonts/FWDCircularTT-MediumItalic.ttf") format("truetype");
    font-weight: normal;
}

input[type="button"] {
    outline: none;
}
input[type="button"]::-moz-focus-inner {
    border: 0;
}
